// import logo from "./logo.svg";
import { Link, Route, Switch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faCar } from "@fortawesome/free-solid-svg-icons";
import siteLogo from "./logo.png";
import "./App.css";

function App() {
    return (
        <div className="App">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
                <Link to="/" className="navbar-brand m-0 m-auto">
                    <img src={siteLogo} width="28" height="28" alt="" />
                    <span className="pl-2">PerTras</span>
                </Link>
            </nav>
            <div className="content">
                <Switch>
                    <Route path="/expense-tracker">
                        <ExpenseTracker />
                    </Route>
                    <Route path="/fuel-tracker">
                        <FuelTracker />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

function Home() {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="list-group mt-5">
                        <Link
                            to="/expense-tracker"
                            className="list-group-item list-group-item-action"
                        >
                            <div className="d-flex align-items-center">
                                <div className="mr-3">
                                    <FontAwesomeIcon
                                        icon={faWallet}
                                        size="2x"
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <div>
                                        <strong>Expense Tracker</strong>
                                    </div>
                                    <div>
                                        A simple tracker to track/record
                                        day-to-day expenses
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link
                            to="/fuel-tracker"
                            className="list-group-item list-group-item-action"
                        >
                            <div className="d-flex align-items-center">
                                <div className="mr-3">
                                    <FontAwesomeIcon icon={faCar} size="2x" />
                                </div>
                                <div className="d-flex flex-column">
                                    <div>
                                        <strong>Vehicle Fuel Tracker</strong>
                                    </div>
                                    <div>
                                        A tracker to track/record vehicle fuel
                                        filling
                                    </div>
                                </div>
                            </div>
                        </Link>
                        {/* <li
                            to="/expense-tracker"
                            className="list-group-item list-group-item-action disabled"
                        >
                            Vehicle Milestone Tracker
                        </li> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ExpenseTracker() {
    return (
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col">
                    <iframe
                        title="Expense Tracker"
                        src="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAZAAOAWaZZUM0ZBSUJBWFQ2NU5ISDhHQjRBMFIzUVpMWi4u&embed=true"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        width="100%"
                        allowFullScreen={true}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        msallowfullscreen="true"
                        className="expense-tracker-iframe"
                    >
                        {" "}
                    </iframe>
                </div>
            </div>
        </div>
    );
}

function FuelTracker() {
    return (
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col">
                    <iframe
                        title="Vehicle Fuel Tracker"
                        src="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAZAAOAWaZZURFlLREVOUEJINVE5Q1JKQVRBMkdaWUYyNy4u&embed=true"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        width="100%"
                        allowFullScreen={true}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        msallowfullscreen="true"
                        style={{
                            border: "none",
                            maxWidth: "100%",
                            maxHeight: "100vh",
                        }}
                        className="fuel-tracker-iframe"
                    >
                        {" "}
                    </iframe>
                </div>
            </div>
        </div>
    );
}

export default App;
